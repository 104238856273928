
import { defineComponent } from 'vue';
import { GoogleMap, Marker } from 'vue3-google-map';
import globals from '../../helpers/globals';
import FormButton from '../../components/forms/Button.vue';
import StatusPin from '../../components/StatusPin.vue';
import AccountDataService from '../../services/AccountDataService';
import SegmentDataService from '../../services/SegmentDataService';
import FileDataService from '../../services/FileDataService';
import Segment from '../../types/Segment';
import ModalData from '../../types/ModalData';

export default defineComponent({
  name: 'CustomerFile',
  mixins: [
    globals,
  ],
  components: {
    StatusPin,
    FormButton,
    GoogleMap,
    Marker,
  },
  data() {
    return {
      center: {
        lat: Number,
        lng: Number,
      },
    };
  },
  created() {
    const vm = this;

    /* LOAD DEPENDENCIES IF DONT ALREADY EXIST */
    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      vm.$store.dispatch('setSelectedFile', data);

      if (vm.$store.state.selected.file.time_slot) {
        vm.center.lat = vm.$store.state.selected.file.time_slot.agency.lat;
        vm.center.lng = vm.$store.state.selected.file.time_slot.agency.long;
      }

      vm.init();
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', false);
      this.$store.dispatch('toggleSideBar', false);

      this.$store.dispatch('toggleTopBar', true);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      const token = this.getAccessToken();

      AccountDataService.getFile(token, this.$route.params.file as string)
        .then((response: any) => {
          callback(response.data.data);
        });
    },
    goToNextStep(event: any) {
      /* FLAG STEP AS COMPLETED BEFORE MOVING TO NEXT SCREEN */
      // this.$store.dispatch('setHomeAsCompleted', true);

      /* MOVE TO NEXT SCREEN */
      event.preventDefault();

      this.$router.push({
        name: 'SegmentSelection',
        params: {},
      });
    },
    goToPanel(event: any) {
      /* FLAG STEP AS COMPLETED BEFORE MOVING TO NEXT SCREEN */
      // this.$store.dispatch('setHomeAsCompleted', true);

      /* MOVE TO NEXT SCREEN */
      event.preventDefault();

      this.$router.push({
        name: 'CustomerPanel',
        params: {},
      });
    },
    download() {
      this.$store.dispatch('isLoading', true);

      const token = this.getAccessToken();

      FileDataService.download(token, this.$route.params.file as string)
        .then((response: any) => {
          const saveData = (function () {
            const a = document.createElement('a');
            document.body.appendChild(a);

            return function (data: any, fileName: string) {
              const blob = new Blob([data], { type: 'application/pdf' });
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
            };
          }());

          const now = new Date();

          this.$store.dispatch('isLoading', false);

          saveData(response.data, `demande-financement-bna-${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}-${now.getHours()}-${now.getMinutes()}.pdf`);
        })
        .catch((e: Error) => { /* */ });
    },
    downloadRequiredDocs() {
      this.$store.dispatch('isLoading', true);

      const token = this.getAccessToken();

      SegmentDataService.download(token, this.$store.state.selected.segment as Segment)
        .then((response: any) => {
          const saveData = (function () {
            const a = document.createElement('a');
            document.body.appendChild(a);

            return function (data: any, mimeType: string, fileName: string) {
              const blob = new Blob([data], { type: mimeType });
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
            };
          }());

          this.$store.dispatch('isLoading', false);

          saveData(response.data, response.headers['content-type'], 'documents-a-presenter.docx');
        })
        .catch((e: Error) => { /* */ });
    },
    modalShow(file: any) {
      const vm = this;

      if (['pending', 'completed'].includes(file.public_state as string)) {
        vm.$store.dispatch('modal', {
          id: 'file-pre-creation-modal',
          title: 'Important !',
          description: 'Voulez vous vraiment poursvuire cette demande de financement ?',
          actions: {
            cancel: {
              label: 'Non, pas maintenant',
              callback: () => { /* */ },
            },
            proceed: {
              label: 'Oui',
              callback: () => {
                if (file.kyc === null) {
                  let kyc = 'KYC';

                  if (file.simulation.segment.pid === 'pro') kyc = 'KYCProf';
                  else if (file.simulation.segment.pid === 'corp') kyc = 'KYCCorp';

                  vm.$router.push({
                    name: kyc,
                    params: {
                      file: file.pid,
                    },
                  });
                } else if (file.finance_info === null && file.simulation.segment.pid !== 'part') {
                  vm.$router.push({
                    name: 'FinanceInfo',
                    params: {
                      file: file.pid,
                    },
                  });
                } else if (file.documents.length < file.document_types.length) {
                  vm.$router.push({
                    name: 'DocumentUpload',
                    params: {
                      file: file.pid,
                    },
                  });
                } else if (file.time_slot === null) {
                  vm.$router.push({
                    name: 'RDV',
                    params: {
                      file: file.pid,
                    },
                  });
                } else {
                  vm.$router.push({
                    name: 'End',
                    params: {
                      file: file.pid,
                    },
                  });
                }
              },
            },
          },
        } as ModalData);
      }

      return true;
    },
    linkToGoogleMaps(lat: number, lng: number) {
      return `https://maps.google.com/?q=${lat},${lng}`;
    },
    fullProductName() {
      const financeType = this.$store.state.selected.financeType.name;
      const product = this.$store.state.selected.product.fullname;

      return `${financeType} - ${product}`;
    },
    createdAt() {
      return `Créé le ${this.$store.state.selected.file.friendly_date}`;
    },
  },
});
